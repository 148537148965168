import { AnalyticsBrowser } from '@segment/analytics-next'
import { getGapBetweenRanges } from '~/utils/array'
import { formDataHash } from '~/utils/form-data'
import type {
  AffiliationPermissions,
  AffiliationView,
  KehilaKeyEnum,
  Peilut,
  PeilutCardDisplay,
  Shichva,
} from '../generated/api'

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  },
  { disable: import.meta.env.VITE_RUNTIME_ENVIRONMENT !== 'production' },
)

export function formSubmitted({ target }: { target: HTMLFormElement }) {
  const formData = new FormData(target)

  const payload = formDataHash({ formData })

  analytics.track('Form Submitted', {
    action: target.action,
    method: target.method,
    name: target.name,
    payload,
  })
}

export function trackActivityClicked({
  activity,
  stripName,
  activityIndex,
  stripIndex,
  currentCycleOrder,
  affiliation,
  switchKehila,
  kehilaName,
}: {
  activity: PeilutCardDisplay
  stripName: string
  activityIndex: number
  stripIndex: number
  currentCycleOrder: number
  affiliation: AffiliationPermissions
  switchKehila?: KehilaKeyEnum
  kehilaName?: string
}) {
  let activityCycleGap
  let shichvaGap
  let classification = activity.classifications?.length ? activity.classifications[0] : null
  if (activity.cycles) {
    const activityCycleIds = activity.cycles.map((cycle) => cycle.order)
    activityCycleGap = getGapBetweenRanges(activityCycleIds, [currentCycleOrder])
  }

  if (classification) {
    const classificationShchavotOrder = classification.shchavot.map((shichva) => shichva.order)

    if (affiliation.shchavot?.length) {
      const affiliationShchavotOrder = affiliation.shchavot.map((shichva: Shichva) => shichva.order)
      shichvaGap = getGapBetweenRanges(affiliationShchavotOrder, classificationShchavotOrder)
    }
  }

  analytics.track('Activity clicked', {
    activityType: activity.peilutType,
    stripName: stripName,
    stripIndex: stripIndex,
    activityIndex: activityIndex,
    classification: classification,
    activity: activity,
    isFavorited: activity.isFavorited,
    cycleGap: activityCycleGap ? activityCycleGap : 0,
    shichvaGap: shichvaGap ? shichvaGap : 0,
    kehila: kehilaName,
    guestView: !!switchKehila,
  })
}

export function trackActivityFavorited({
  action,
  activity,
  stripName,
  activityIndex,
  stripIndex,
  currentCycleOrder,
  source,
  affiliation,
}: {
  action: string
  activity: PeilutCardDisplay
  stripName: string
  activityIndex: number
  stripIndex: number
  currentCycleOrder: number
  source: string
  affiliation: AffiliationPermissions
}) {
  let activityCycleGap
  let shichvaGap
  let classification = activity.classifications?.length ? activity.classifications[0] : null
  if (activity.cycles) {
    const activityCycleIds = activity.cycles.map((cycle) => cycle.order)
    activityCycleGap = getGapBetweenRanges(activityCycleIds, [currentCycleOrder])
  }

  if (classification) {
    const classificationShchavotOrder = classification.shchavot.map((shichva) => shichva.order)

    if (affiliation.shchavot?.length) {
      const affiliationShchavotOrder = affiliation.shchavot.map((shichva: Shichva) => shichva.order)
      shichvaGap = getGapBetweenRanges(affiliationShchavotOrder, classificationShchavotOrder)
    }
  }

  analytics.track(action === 'favorite-activity' ? 'Activity favorited' : 'Activity unfavorited', {
    stripName: stripName,
    source: source,
    stripIndex: stripIndex,
    activityIndex: activityIndex,
    classification: classification,
    activity: activity,
    isFavorited: activity.isFavorited,
    cycleGap: activityCycleGap ? activityCycleGap : 0,
    shichvaGap: shichvaGap ? shichvaGap : 0,
  })
}

export enum AdminAffiliationActions {
  DeclineRequest = 'DECLINE REQUEST',
  ApproveRequest = 'APPROVE REQUEST',
  PopupEdit = 'POPUP EDIT',
  SaveEdit = 'SAVE EDIT',
  PopupRemove = 'POPUP REMOVE',
  Remove = 'REMOVE',
}

export function trackAdminAffiliationAction({
  action,
  affiliation,
}: {
  action: `${AdminAffiliationActions}`
  affiliation: AffiliationView
}) {
  analytics.track(action, {
    changedAffiliationId: affiliation.id,
    changedAffiliationPosition: affiliation.position,
    changedAffiliationKehila: affiliation.kehila,
    changedAffiliationMahoz: affiliation.mahoz,
    changedAffiliationKen: affiliation.ken,
  })
}

export function trackActivityDownloaded({ activity }: { activity: Peilut }) {
  analytics.track('Activity Downloaded', { activity })
}
